.main {
  padding: 140px 0px 140px 0px;
  width: 100%;
  background: linear-gradient(to bottom, #080707, #080707, #050505);
}
.boxCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 120px;
}
.boxCol::-webkit-scrollbar {
  display: none;
}
.mainBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
}
.mainBox h1,
.mainBox h2,
.mainBox p {
  color: white;
  text-align: start;
  align-self: flex-start;
}
.head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.head img {
  width: 80px;
}
.head p {
  color: rgb(182, 182, 182);
  cursor: pointer;
}
.info {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}
.img {
  width: 100%;
  border-radius: 12px;
  cursor: pointer;
}
.contentCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 30px;
}
.contentCol h1 {
  color: #ec1d23;
}
.contentBox {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 15px;
  cursor: pointer;
}
.contentBox h4 {
  color: white;
  text-align: start;
  align-self: flex-start;
}
.contentBox p {
  color: rgb(186, 186, 186);
  text-align: start;
  align-self: flex-start;
}
.contentBox img {
  width: 200px;
  height: 120px;
  border-radius: 10px;
}
@media (max-width: 992px) {
  .main {
    padding: 60px 0px 60px 0px;
  }
  .boxCol {
    height: 500px;
  }
  .head {
    flex-direction: column;
    gap: 20px;
  }
  .head img {
    width: 60px;
    height: 60px;
  }
  .mainBox h1,
  .mainBox p {
    align-self: center;
    text-align: center;
  }
  .contentBox img {
    width: 130px;
    height: 80px;
  }
}
