.box {
  width: 100%;
  height: 100%;
  padding: 60px 10px 60px 10px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 18px;
  max-width: 320px;
}
.box h4 {
  align-self: center;
  text-align: center;
}
.box img {
  width: 50px;
  height: 50px;
}
