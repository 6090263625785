.testimonials_swiper {
  width: 100%;
  height: 100%;
}
.testimonials_swiper .swiper-slide {
  padding: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.testimonials_swiper .swiper-button-next,
.testimonials_swiper .swiper-button-prev {
  border: 3px solid transparent;
  color: black;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #F9D4D0;
}
.testimonials_swiper .swiper-button-next::after,
.testimonials_swiper .swiper-button-prev::after {
  font-size: 15px;
  font-weight: 900;
}
.testimonials_swiper .swiper-pagination-bullet-active {
  background-color: #e53e29 !important;
}
