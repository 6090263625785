.main {
  width: 100%;
  min-height: 78vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
}
.shopHero{
  min-height: 45vh;
}
.inner {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}
.contentCont {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}
.contentCont h1,
.contentCont h2,
.contentCont p {
  text-align: center;
  align-self: center;
  padding: 0px 10% 0px 10%;
}
@media (max-width: 992px) {
  .main {
    min-height: 60vh;
  }
}
@media (max-width: 482px) {
  .main {
    min-height: 28vh;
  }
  .contentCont {
    padding: 20px 5% 20px 5%;
  }
  .contentCont p {
    padding: 0px 5% 0px 5%;
  }
}
