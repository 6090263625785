.contentCol,
.formCol form {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
}
.info img {
  width: 30px;
  height: 30px;
  padding: 8px;
  border-radius: 50%;
  background-color: #e53e29;
}
.info p {
  color: #8b8b8b;
}
.formCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.071);
  padding: 65px 50px 65px 50px;
}
.formCol h1,
.formCol p,
.contentCol h1,
.contentCol h2 {
  align-self: flex-start;
  text-align: start;
}
.formCol form {
  gap: 30px;
}
.formCol form input {
  width: 100%;
  border: none;
  border-bottom: 1px solid rgb(200, 200, 200);
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  color: #383838;
  padding: 0px 0px 10px 0px;
}
.formCol form input:focus {
  outline: none;
}
.formCol form button {
  align-self: flex-start;
}
