.box {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 62px rgba(204, 204, 204, 0.726);
}
.box img {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.box h3,
.box p {
  text-align: start;
  align-self: flex-start;
}
.box p {
  color: rgb(172, 172, 172);
}
.contentCol {
  width: 100%;
  padding: 20px 30px 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
.foot {
  padding-top: 15px;
  border-top: 1px solid rgb(235, 235, 235);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.foot h4 {
  color: #e53e29;
}
.foot a {
  text-decoration: none;
}
.readMore {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}
.readMore img {
  width: 16px;
  height: 16px;
}
.readMore p {
  color: rgb(99, 99, 99);
}
