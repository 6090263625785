.main {
  padding: 200px 0px 80px 0px;
  width: 100%;
  height: auto;
}
.innerCont {
  width: 100%;
  height: auto;
  padding: 50px 0px 50px 0px;
  background-color: #f9f9f9;
}
.contentCont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
}
.cta {
  margin-top: -160px;
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 8px;
  box-shadow: 0px 0px 22px rgba(204, 204, 204, 0.726);
  background-color: white;
  gap: 15%;
  padding: 50px 40px 50px 40px;
}
.cta img {
  height: 260px;
}
.content {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.content h4,
.content h1,
.content button {
  align-self: center;
  text-align: center;
}
.iconsCont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.iconsCont img {
  max-width: 150px;
}

.main2 {
  padding: 80px 0px 80px 0px;
  width: 100%;
  height: auto;
  background-image: linear-gradient(to right, #fff, #bf2e1b19);
}
.contentCont2 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.contentCont2 h2,
.contentCont2 h1,
.contentCont2 a {
  align-self: center;
  text-align: center;
  padding: 0px 10% 0px 10%;
  text-decoration: none;
}

@media (max-width: 992px) {
  .main2 {
    padding: 60px 0px 60px 0px;
  }
  .iconsCont {
    justify-content: center;
    gap: 20px;
  }
  .cta {
    flex-direction: column;
    padding: 35px 25px 35px 25px;
    max-height: 500px;
    gap: 30px;
  }
  .cta img {
    max-height: 220px;
  }
  .content {
    width: 100%;
  }
}
