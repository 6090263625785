.cont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.boxCont {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  border: 1px solid rgba(204, 204, 204, 0.726);
  cursor: pointer;
  transition: 0.2s ease;
}
.boxCont:hover {
  border: none;
  box-shadow: 0px 3px 22px rgba(204, 204, 204, 0.726);
}
.boxCont h5 {
  font-weight: 500;
}
.boxCont h4 {
  color: #dd1d26;
}
.box {
  width: 100%;
  height: auto;
  min-height: 260px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
}
.content h3,
.content h4 {
  text-align: center;
  align-self: center;
}
.foot {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 15px;
}
.foot h3 {
  color: #717171;
  text-decoration: line-through;
  text-decoration-thickness: 2.8px;
  text-decoration-color: #717171;
}
@media (max-width: 1200px) {
  .box {
    min-height: 265px;
  }
  .sizeCont h6 {
    font-size: 11px;
  }
  .sizes h6 {
    width: 25px;
    height: 25px;
  }
}
@media (max-width: 992px) {
  .boxCont {
    max-width: 400px;
  }
  .productPage {
    min-height: 320px;
  }
}
@media (max-width: 600px) {
  .productPage {
    min-height: 260px;
  }
}
