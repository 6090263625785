.headerDesktop {
  padding: 25px 0px 25px 0px;
  width: 100%;
  background: transparent;
}
.main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.logo {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 50px;
}
.logo img {
  width: 130px;
  height: 50px;
}
.tabs {
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  padding-right: 50px;
}
.tabLink {
  color: rgb(35, 35, 35);
  text-decoration: none;
  font-family: "Rajdhani", sans-serif;
  font-size: 16px;
  font-weight: 600;
  transition: 0.2s ease;
  text-transform: uppercase;
}
.tabLinkActive {
  color: #e53e29;
}
.tabLink:hover {
  color: #e53e29;
}
.infoCont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  gap: 17px;
}
.search {
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: transparent;
  border-bottom: 1px solid rgb(184, 184, 184);
}
.search input {
  width: 75%;
  background-color: transparent;
  border: 0px solid;
  padding: 0;
  margin: 0;
}
.search input:focus {
  outline: none;
}
.search input::placeholder {
  font-size: 13px;
  font-family: "Montserrat", "sans-serif";
  color: #000000;
}
.search img {
  height: 16px;
  width: 16px;
  cursor: pointer;
}
.iconText {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: transparent;
  gap: 5px;
}
.iconText img {
  width: 20px;
  height: 20px;
}
.username a.dropdown-item:hover {
  background-color: #000000c8;
}
.dropdown {
  position: relative;
  display: inline-block;
  z-index: 999;
}
.dropdownContentCont {
  height: auto;
  display: none;
  position: absolute;
  min-width: 250px;
  transition: 0.3s ease;
}
.dropdownContent {
  width: 100%;
  background-color: #f9f9f9;
  box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.2);
  padding: 24px 16px;
  margin-top: 18px;
  border-radius: 5px;
  margin-top: 15px;
}
.dropdown:hover .dropdownContentCont {
  display: block;
  animation: fadeInFromNone 0.4s ease-out;
}
.dropdownContentLink {
  text-decoration: none;
}
.dropdownContentLink:hover h6 {
  color: #e53e29;
}
.dropdownContentLink h6 {
  text-align: center;
  align-self: center;
  font-family: "Rajdhani", sans-serif;
  color: black;
  margin: 0px;
}
.dropdownContentActiveLink h6 {
  color: #e53e29;
}
@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

/* MOBILE NAVIGATION --> */

.mobSearch {
  display: none;
}
.mobHeader {
  display: none;
}
.mobNav {
  padding: 15px 0px 15px 0px;
  width: 100%;
}
.mobIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  gap: 2px;
  color: #000000;
}
.mobIcon:hover {
  color: #000000;
}
.mobIcon:hover img {
  transform: scale(1.1);
}
.mobIcon img {
  width: 18px;
  height: 17px;
  transition: 0.2s ease-in-out;
}
.mobToggler {
  font-size: 22px;
  border: 0.1px solid;
  border-color: transparent;
  background-color: transparent;
  color: #383838;
  padding-right: 0px;
}
.mobToggler:focus {
  box-shadow: none;
}

@media (max-width: 1400px) {
  .headerDesktop {
    display: none;
  }

  .mobHeader {
    display: block;
    width: 100%;
  }
  .mobSearch {
    display: block;
    padding: 35px 25px 35px 25px;
    cursor: pointer;
    width: 100%;
  }
  .searchCont {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .search {
    width: 70%;
    height: 20px;
    border-bottom: none;
  }
  .search input {
    width: 80%;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0;
    margin: 0;
  }
  .search input::placeholder {
    font-size: 16px;
    font-weight: 500;
  }
  .search img {
    height: 24px;
    width: 24px;
  }
  .close {
    width: 25px;
    height: 25px;
  }
  .link {
    color: black;
    text-decoration: none;
    font-family: "Rajdhani", sans-serif;
    align-self: flex-start;
    text-align: start;
    font-size: 14px;
    font-weight: 500;
  }
  .activeLink {
    color: #e53e29;
    text-decoration: none;
    font-family: "Rajdhani", sans-serif;
    align-self: flex-start;
    text-align: start;
    font-size: 14px;
    font-weight: 500;
  }
  .link:hover {
    color: #454545;
  }
  .activeLink:hover {
    color: #454545;
  }
  .mobNav a {
    border: none;
    box-shadow: none;
  }
  .mobNav div {
    border: none;
    box-shadow: none;
  }
  .username a {
    align-self: flex-start;
    text-align: start;
    font-size: 11px;
    margin-right: 11px;
    font-family: "Poppins", sans-serif;
    color: #000;
    padding: 0;
  }
  .username a:focus {
    color: black;
  }
}
@media (max-width: 767px) {
  .logoCont {
    width: 25%;
  }
  .logoCont img {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .logoCont {
    width: 28%;
  }
}
