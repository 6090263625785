.hero_swiper {
  width: 100%;
  cursor: pointer;
  user-select: none;
}
.hero_swiper .swiper-slide {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 0px 60px 0px;
}
.hero_swiper .swiper-pagination {
  margin-bottom: -10px;
}
.hero_swiper .swiper-pagination-bullet {
  padding: 8px;
  margin: 8px;
  background-color: black !important;
  border: black;
}
.hero_swiper .swiper-pagination-bullet-active {
  background-color: #E53E29 !important;
}
@media (max-width: 767px) {
  .hero_swiper .swiper-pagination-bullet {
    padding: 6px;
    margin: 6px;
  }
}
