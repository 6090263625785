.main {
  width: 100%;
  height: auto;
}
.footer {
  padding: 0px 0px 45px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: #191919;
}
.newsletter {
  background-color: #e53e29;
  width: 85%;
  padding: 80px 120px 80px 120px;
  margin-top: -150px;
  border-radius: 8px;
}
.pillCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.pillCont h1,
.pillCont h2 {
  color: #fff;
  text-align: center;
  align-self: center;
}
.input {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: transparent;
  border-radius: 6px;
  padding: 0px 0px 0px 30px;
  border: 1px solid white;
}
.input input {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  width: 60%;
  color: #fff;
}
.input input:focus {
  outline: none;
}
.input input::placeholder {
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #ffffff;
}
.input button {
  padding: 0px 35px 0px 35px;
  height: 100%;
  background-color: white;
  color: #e01d25;
  border: none;
  border-radius: 5px;
}
.input button:hover {
  border: none;
  background-color: rgb(248, 248, 248);
}
.contentCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-direction: column;
  gap: 30px;
  padding-right: 8%;
}
.contentCol img {
  width: 100%;
  max-width: 150px;
}
.contentCol p {
  color: rgb(220, 220, 220);
}
.linkCol {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-top: 15px;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex-direction: column;
  gap: 18px;
}
.linkCol a {
  text-decoration: none;
  color: white;
  text-align: start;
  align-self: flex-start;
}
.linkCol a h4 {
  color: #fff;
}
.linkCol a:hover h4 {
  color: #e4e4e4;
}
.infoCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}
.iconText {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  cursor: pointer;
}
.iconText p {
  color: rgb(220, 220, 220);
}
.iconText img {
  width: 32px;
  height: 32px;
}
.bottomRow {
  padding: 15px 0px 15px 0px;
  width: 100%;
  background-color: #ffffff;
}
.bottomRowInner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.socials {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 16px;
}
.socials a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #f0f0f0;
  transition: 0.2s ease;
}
.socials svg {
  width: 16px;
  height: 16px;
}
.socials a:hover {
  background-color: red;
}
.socials a:hover svg {
  fill: #fff;
}
@media (max-width: 992px) {
  .newsletter {
    width: 100%;
  }
  .contentCol {
    padding-right: 0px;
    justify-content: center;
    align-items: center;
  }
  .contentCol p {
    text-align: center;
    align-self: center;
  }
  .linkCol {
    padding-top: 0px;
    padding-left: 0px;
  }
  .linkCol a {
    text-align: center;
    align-self: center;
  }
  .iconText {
    justify-content: center;
  }
  .bottomRowInner {
    flex-direction: column;
    gap: 10px;
  }
  .bottomRowInner p {
    width: 100%;
    align-self: center;
    text-align: center;
  }
  .socials {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .newsletter {
    width: 100%;
    padding: 40px 30px 40px 30px;
    margin-top: -100px;
  }
  .input {
    padding: 0px 0px 0px 15px;
  }
  .input button {
    padding: 0px 15px 0px 15px;
  }
}
