.swiper2 {
  width: 100%;
  cursor: pointer;
}
.swiper2Img {
  width: 100%;
  border-radius: 35px;
}
.swiper {
  width: 100%;
  max-height: 130px;
  cursor: pointer;
  padding-top: 10px;
}
.swiper img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
.box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 30px;
  padding-left: 20px;
}
.headingCont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
.headingCont h1,
.headingCont h2,
.headingCont h3 {
  align-self: flex-start;
  text-align: start;
}
.headingCont h2 {
  color: black;
  font-weight: 900;
}
.priceRow {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: row;
  gap: 20px;
}
.priceRow h3 {
  color: #717171;
  text-decoration: line-through;
  text-decoration-thickness: 2.8px;
  text-decoration-color: #717171;
}
.customCont {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
}
.custom1 {
  width: 30%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 35px;
}
.custom2 {
  width: 70%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 20px;
}
.custom2 h4 {
  font-style: normal;
  color: #000000;
}
.custom2 h5 {
  color: #8d8d8d;
}
.buttonCont {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}
.input {
  width: 110px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid rgb(223, 223, 223);
  background-color: rgb(240, 240, 240);
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}
.input img {
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.input span {
  padding: 10px 20px 10px 20px;
  background-color: rgb(255, 255, 255);
}
.addToCart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  background-color: #e53e29;
  border-radius: 5px;
  border: 1px solid #e53e29;
  padding: 11px 38px 11px 38px;
  color: #fff;
  box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.256);
  text-transform: uppercase;
  font-family: "Rajdhani", sans-serif;
  font-size: 16px;
  font-weight: 600;
  transition: 0.2s ease;
}
.addToCart:hover {
  background-color: #c01600;
  border: 1px solid #c01600;
}
.addToCart:disabled {
  cursor: pointer;
  background-color: #e53f29b3;
  border: none;
  box-shadow: none;
}
.infoCont {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 10px;
}
@media (max-width: 1200px) {
  .custom2 {
    gap: 22px;
  }
}
@media (max-width: 992px) {
  .box {
    padding-left: 0px;
  }
}
@media (max-width: 480px) {
  .headingCont {
    gap: 10px;
  }
  .headingCont h2 {
    font-size: 20px;
  }
  .headingCont h5 {
    font-size: 21px;
  }
  .input {
    width: 100px;
    gap: 14px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .input span{
    padding: 6px 10px 6px 10px;
  }
  .addToCart {
    padding: 8px 26px 8px 26px;
    font-size: 12px;
  }
  .custom2 {
    padding-left: 11vw;
    gap: 20px;
  }
  .sizes span {
    font-size: 12px;
  }
}
