.main {
  height: 100vh;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  background-blend-mode: overlay;
  background: #f3f3f3;
}
.inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contentCont {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.contentCont h1,
.contentCont button,
.contentCont a {
  align-self: flex-start;
  text-align: start;
}
.contentCont p {
  padding-top: 15px;
  padding-bottom: 15px;
}
.button1 {
  padding: 12px 46px 12px 46px;
}
.imgCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.imgCol img {
  width: 100%;
  height: 100%;
}
.swiperCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 1400px) {
  .contentCont {
    padding-right: 0px;
  }
}
@media (max-width: 1400px) {
  .main {
    height: auto;
    padding: 0px 0px 40px 0px;
    gap: 30px;
  }
  .contentCont h1,
  .contentCont button,
  .contentCont a,
  .contentCont p {
    align-self: center;
    text-align: center;
  }
}

/* @media (max-height: 750px) {
  .main {
    height: auto;
    gap: 50px;
    padding: 0px 0px 60px 0px;
  }
  .backgroundImage {
    width: 72%;
  }
} */
