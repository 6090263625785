.main {
  padding: 80px 0px 80px 0px;
  width: 100%;
}
.cont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cont h1,
.cont h2,
.cont h3,
.cont p {
  align-self: center;
  text-align: center;
}
.swiperCont {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.swiperSlide {
  height: 450px;
}
.box {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  border-radius: 5px;
  background-color: white;
  border: 1px solid rgba(204, 204, 204, 0.726);
  cursor: pointer;
  transition: 0.2s ease;
}
.box:hover {
  border: none;
  box-shadow: 0px 0px 22px rgba(204, 204, 204, 0.726);
}
.box img {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.box h2,
.box p {
  text-align: center;
  align-self: center;
}
.box p {
  color: rgb(172, 172, 172);
}
.contentCol {
  width: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
@media (max-width: 1200px) {
  .main {
    padding: 60px 0px 60px 0px;
  }
  .swiperSlide {
    height: 400px;
  }
}
