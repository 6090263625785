.main {
  width: 100%;
  padding: 80px 0px 80px 0px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.imgCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgCol img {
  width: 100%;
  height: 100%;
}
.contentCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.contentCol h1,
.contentCol h2,
.contentCol button,
.contentCol a {
  align-self: flex-start;
  text-align: start;
  text-decoration: none;
}
@media (max-width: 992px) {
  .main {
    padding: 60px 0px 60px 0px;
  }
}
