.main {
  padding: 100px 0px 260px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: #f9f9f9;
}
.contentCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contentCol h1,
.contentCol p,
.contentCol a {
  text-align: start;
  align-self: flex-start;
  text-decoration: none;
}
.boxCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 30px 40px 30px;
  border-radius: 16px;
  background-color: white;
  box-shadow: 0px 3px 18px rgba(65, 65, 65, 0.21);
}
.box {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  gap: 15px;
}
.box img,
.box p,
.box h2 {
  align-self: center;
  text-align: center;
}
.image {
  width: 115px;
  height: 115px;
}
.review {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 15px;
}
.review h2 {
  align-self: center;
  color: black;
}
.review img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
@media (max-width: 992px) {
  .main {
    padding: 60px 0px 220px 0px;
  }
  .boxCol {
    padding: 0px 30px 40px 30px;
  }
  .box {
    padding: 30px;
  }
}
