.swiper_box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.swiperSlide {
  min-height: 480px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}
@media (max-width: 1200px) {
  .swiperSlide {
    min-height: 400px;
  }
}
