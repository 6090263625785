.services_swiper {
  cursor: pointer;
  user-select: none;
}
.services_swiper .swiper-pagination {
  margin-bottom: -10px;
}
.services_swiper .swiper-pagination-bullet {
  padding: 8px;
  margin: 8px;
  background-color: transparent;
  border: 2px solid black;
}
.services_swiper .swiper-pagination-bullet-active {
  background-color: #E80080;
  border-color: #E80080;
}
@media (max-width: 600px) {
  .services_swiper .swiper-pagination-bullet {
    padding: 7px;
  }
  .services_swiper .swiper-button-next {
    padding: 1px;
  }
}
