* {
  user-select: none;
}
*::-webkit-scrollbar {
  width: 14px;
}
*::-webkit-scrollbar-thumb {
  background-color: #ec1d23;
  border-radius: 4px;
}
*::-webkit-scrollbar-track {
  background-color: #232323;
}
.main {
  padding: 80px 0px 80px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
}
.heading_capital {
  font-family: "Rajdhani", sans-serif;
  font-size: 60px;
  text-transform: uppercase;
  color: #1d1d1d;
  font-weight: 900;
  margin: 0;
}
.heading_capital span {
  color: #e53e29;
  font-family: "Rajdhani", sans-serif;
  font-size: 60px;
  text-transform: uppercase;
  font-weight: 900;
  margin: 0;
}
.h1_main {
  font-family: "Rajdhani", sans-serif;
  font-size: 50px;
  text-transform: uppercase;
  color: #1d1d1d;
  font-weight: 900;
  margin: 0;
}
.h1_main span {
  color: #e53e29;
  font-family: "Rajdhani", sans-serif;
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 900;
  margin: 0;
}
.h2_main {
  font-family: "Rajdhani", sans-serif;
  font-size: 24px;
  color: #e53e29;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
}
.h3_main {
  font-family: "Rajdhani", sans-serif;
  font-size: 22px;
  color: #1d1d1d;
  font-weight: 800;
  margin: 0;
}
.h3_main span {
  color: rgba(0, 0, 0, 1);
}
.h4_main {
  text-transform: uppercase;
  font-family: "Rajdhani", sans-serif;
  font-size: 17px;
  color: #1d1d1d;
  font-weight: 100;
  margin: 0;
}
.h4_main span {
  color: rgba(0, 0, 0, 1);
}
.h5_main {
  font-family: "Rajdhani", sans-serif;
  font-size: 15px;
  color: #1d1d1d;
  font-weight: 400;
  margin: 0;
}
.h5_main span {
  color: rgba(0, 0, 0, 1);
}
.para_main {
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  text-align: start;
  color: #717171;
  margin: 0;
}
.button_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  background-color: #e53e29;
  border-radius: 5px;
  border: 1px solid #e53e29;
  padding: 8px 32px 8px 32px;
  color: #fff;
  box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.256);
  text-transform: uppercase;
  font-family: "Rajdhani", sans-serif;
  font-size: 16px;
  font-weight: 600;
  transition: 0.2s ease;
}
.button_main:hover {
  background-color: #c01600;
  border: 1px solid #c01600;
}
.button_main img {
  width: 20px;
  height: 20px;
}
@media (max-width: 992px) {
  .main {
    padding: 60px 0px 60px 0px;
  }
  .heading_capital {
    font-size: 25px;
  }
  .heading_capital span {
    font-size: 25px;
  }
  .h1_main {
    font-size: 23px;
  }
  .h1_main span {
    font-size: 23px;
  }
  .h2_main {
    font-size: 15px;
  }
  .h3_main {
    font-size: 14px;
  }
  .h4_main {
    font-size: 13px;
  }
  .h5_main {
    font-size: 13px;
  }
  .para_main {
    font-size: 15px;
  }
  .button_main {
    padding: 8px 26px 8px 26px;
    font-size: 12px;
  }
}
@media (max-width: 480px) {
  .heading_capital {
    font-size: 23px;
  }
  .heading_capital span {
    font-size: 23px;
  }
  .heading_main {
    font-size: 22px;
  }
}
.admin-screens-mg-top {
  margin-top: 15vh;
}
.user-screens-mg-top {
  margin-top: 15vh;
}
.formScreenNoMargin {
  padding-top: 0vh;
}
.formScreen h1 {
  text-align: center;
  font-family: "Rajdhani", sans-serif;
  margin-bottom: 3vh;
  font-weight: 900;
  color: #000000;
  text-transform: uppercase;
}
.formScreen a {
  font-family: "Rajdhani", sans-serif;
  font-size: 15px;
  font-weight: 900;
  color: #46045a;
}
.formScreen a:hover {
  color: #c01600;
}
.formScreen a:focus {
  color: #c01600;
}
.formScreen p {
  font-family: "Rajdhani", sans-serif;
  color: #585958;
  text-align: center;
}
.form input[type="text"] {
  width: 100%;
  padding: 12px 20px;
  border: 2px solid rgb(40, 40, 40);
  border-radius: 0;
}
.form input[type="text"]:focus {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 6px;
}
.form input[type="name"] {
  width: 100%;
  padding: 12px 20px;
  border: 2px solid rgb(40, 40, 40);
  border-radius: 0;
}
.form input[type="name"]:focus {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 6px;
}
.form input[type="email"] {
  width: 100%;
  padding: 12px 20px;
  border: 2px solid rgb(40, 40, 40);
  border-radius: 0;
}
.form input[type="email"]:focus {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 6px;
}
.form input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  border: 2px solid rgb(40, 40, 40);
  border-radius: 0;
}
.form input[type="password"]:focus {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 6px;
}
.form textarea {
  width: 100%;
  padding: 12px 20px;
  border: 2px solid rgba(0, 0, 0, 0.35);
  border-radius: 0;
}
.form textarea:focus {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 6px;
  border: 2px solid rgba(0, 0, 0, 0.35);
}
.form label {
  font-size: 18px;
  font-family: "Rajdhani", sans-serif;
  color: rgb(40, 40, 40);
}
.formCheck label {
  font-family: "Rajdhani", sans-serif;
  color: #585958;
  font-size: 15px;
}
.phoneInput p {
  font-size: 15px;
  text-align: left;
  color: #585958;
}
.securityText p {
  font-size: 15px;
  text-align: left;
  color: #585958;
}
.form [placeholder] {
  font-size: 15px;
  font-family: "Rajdhani", sans-serif;
  color: rgb(40, 40, 40);
  line-height: 1.5;
}
.form button {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border: 1px solid #e53e29;
  background-color: #e53e29;
  color: white;
  border-radius: 0px;
  font-family: "Rajdhani", sans-serif;
  font-size: 15px;
}
.form button:hover {
  border: 1px solid #c01600;
  background-color: #c01600;
}
.form button:disabled {
  border: 1px solid #e53e2965;
  background-color: #e53e2965;
  z-index: 999;
}
.googleButton {
  font-family: "Rajdhani", sans-serif;
  font-size: 15px;
}
.orText h1 {
  display: flex;
  flex-direction: row;
  font-size: 20px;
}
.orText h1:before,
h1:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}
.orText h1:before {
  margin-right: 10px;
}
.orText h1:after {
  margin-left: 10px;
}
.reText h1 {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  margin-top: 0.1vh;
  margin-bottom: -0.1vh;
}
.reText h1:before,
h1:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}
.reText h1:before {
  margin-right: 10px;
}
.reText h1:after {
  margin-left: 10px;
}

@media (max-width: 480px) {
  .form [placeholder] {
    font-size: 13px;
  }
  .form label {
    font-size: 15px;
  }
  .securityText p {
    font-size: 13px;
    text-align: left;
    color: #585958;
  }
  .phoneInput p {
    font-size: 13px;
  }
  .formCheck label {
    font-family: "Rajdhani", sans-serif;
    color: #585958;
    font-size: 13px;
  }
}
.orderingScreensNoMargin {
  margin-top: 0vh;
}
.img-back {
  width: 50px;
  margin-bottom: 10px;
  cursor: pointer;
}
.orderingScreens h1 {
  font-family: "Rajdhani", sans-serif;
  margin-bottom: 3vh;
  font-weight: 900;
  color: #000000;
  text-align: center;
}
.orderingScreens h2 {
  font-family: "Rajdhani", sans-serif;
  margin-bottom: 3vh;
  font-weight: 900;
  color: #000000;
}
.orderingScreens h3 {
  font-family: "Rajdhani", sans-serif;
  margin-bottom: 3vh;
  font-weight: 900;
  color: #000000;
}
.orderingScreens h5 {
  font-family: "Rajdhani", sans-serif;
  margin-bottom: 3vh;
  font-weight: 900;
  color: #000000;
}
.orderingScreens h4 {
  font-family: "Rajdhani", sans-serif;
  font-weight: 900;
  color: #000000;
}
.orderingScreens h6 {
  font-family: "Rajdhani", sans-serif;
  margin-bottom: 1.5vh;
  font-weight: 900;
  color: #000000;
}
.orderingScreens span {
  font-family: "DM Sans", sans-serif;
  margin-bottom: 1.5vh;
  font-size: 18px;
  font-weight: 900;
  color: #c01600;
}
.orderingScreens a {
  font-family: "Rajdhani", sans-serif;
  font-size: 15px;
  font-weight: 900;
  color: #4d2c57;
}
.orderingScreens a:hover {
  color: #c01600;
}
.orderingScreens a:focus {
  color: #c01600;
}
.orderingScreens p {
  font-family: "Rajdhani", sans-serif;
  color: #585958;
}
.orderingScreens button {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding-top: 2vh;
  padding-bottom: 2vh;
  border: 1px solid #e53e29;
  background-color: #e53e29;
  color: white;
  border-radius: 0px;
  font-family: "Rajdhani", sans-serif;
  font-size: 15px;
}
.orderingScreens button:hover {
  border: 1px solid #c01600;
  background-color: #c01600;
}
.orderingScreens button:disabled {
  border: 1px solid #e53e29b3;
  background-color: #e53e29b3;
  z-index: 999;
}
.orderingScreens .goBack {
  width: 10%;
}
.orderingScreens select {
  width: 65px;
  height: 35px;
  border: 1px solid;
  border-color: #e53e29;
  border-radius: 20px;
  font-size: 16px;
  font-family: "Rajdhani", sans-serif;
  color: #e53e29;
  font-weight: 900;
  padding: 5px;
}
.orderingScreens select:focus {
  border-color: #e53e29;
}
.orderingScreens .trashIcon {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.orderId span {
  color: #2b2b2b;
  font-size: 26px;
}
.emptyCartLink a {
  font-size: 20px;
}
.user-screens h1 {
  text-align: left;
  font-family: "Rajdhani", sans-serif;
  margin-bottom: 3vh;
  font-weight: 900;
  color: #000000;
}
.user-screens th {
  font-size: 15px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 900;
  color: #000000;
  letter-spacing: 2px;
}
.user-screens td {
  font-size: 13px;
  font-family: "Rajdhani", sans-serif;
  font-weight: 900;
  color: #000000;
  letter-spacing: 2px;
}
.coupon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.coupon input {
  width: 75%;
  background-color: #f6f6f8;
  margin: 0;
  border: none;
  border: 1px solid #717171;
  padding: 10px 15px 10px 15px;
  height: 45px;
  border-radius: 0px;
}
.coupon input::placeholder {
  font-size: 15px;
  font-weight: 400;
  font-family: "Montserrat", "sans-serif";
  color: #717171;
}
.coupon input,
select,
textarea {
  color: #717171;
}
.coupon input:focus {
  border-radius: 0px;
  outline: none;
}
.coupon button {
  width: 25%;
  max-width: 120px;
  padding: 10px 15px 10px 15px;
  height: 45px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: none;
  box-shadow: none;
}
@media (max-width: 768px) {
  .orderingScreens .trashIcon {
    height: 42px;
    width: 42px;
  }
  .dek-table {
    display: none;
  }
  .mob-table {
    display: block;
  }
}
@media (min-width: 768px) {
  .dek-table {
    display: block;
  }
  .mob-table {
    display: none;
  }
}
@media (max-width: 480px) {
  .orderingScreens .orderId {
    font-size: 17px;
    text-align: center;
  }
  .orderId span {
    font-size: 13px;
  }
  .img-back {
    width: 25px;
  }
  .user-screens td {
    font-size: 11px;
    letter-spacing: 0.6px;
  }
  .user-screens a {
    font-size: 10px;
  }
}
