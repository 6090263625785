.contentCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.contentCol h1 {
  align-self: flex-start;
  text-align: start;
}
.contentCol button {
  align-self: flex-start;
  padding: 12px 55px 12px 55px;
}
.boxCont {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.box {
  width: 100%;
  padding: 60px 35px 60px 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 18px;
  box-shadow: 0px 0px 25px rgba(164, 164, 164, 0.301);
  gap: 30px;
}
.box p {
  color: #868686;
  font-weight: 400;
}
.box img {
  width: 60px;
  height: 60px;
}
@media (max-width: 992px) {
  .box {
    padding: 35px 22px 35px 22px;
  }
}
